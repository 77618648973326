import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./select-current-layer.scss";
import LayerSettingsModels from "../../../models/map-data-model-b/layer-setting-models";
import { connect } from "react-redux";
import OpenlayerController from "../../../models/open-layer/openlayer-controller-custom.model";
import * as MapDataStore from "../../../redux/store/map-data/map-data.store";
import { bindActionCreators } from "redux";


interface LayerSettingsModelExtends
  extends LayerSettingsModels.LayerSettingsModel {
  label: string;
  value: string;
  opacity: string;
}

interface InitProps {
  mapData: any;
  listLayers: LayerSettingsModels.LayerSettingsModel[];
  openLayerController?: OpenlayerController;
  GetMapOpacity: Function
}

function AddLabelValueToListLayers(
  listLayer: LayerSettingsModels.LayerSettingsModel[]
): LayerSettingsModelExtends[] {
  const result: LayerSettingsModelExtends[] = [];
  listLayer.map((layerObject) =>
    result.push({
      ...layerObject,
      label: layerObject.name,
      value: String(layerObject.id),
      opacity: "100"
    })
  );
  return result;
}

function SelectCurrentLayerView(props: InitProps) {
  const [layer, setLayer] = useState<any>();
  const [listLayers, setListLayers] = useState<LayerSettingsModelExtends[]>([]);
  const [opacities, setOpacites] = useState<any>();
  useEffect(() => {
    if (props.listLayers && props.listLayers.length > 0) {
      const layers = AddLabelValueToListLayers(props.listLayers);
      setListLayers(layers);
      !layer && setLayer(layers[0]);
    }
    setOpacites(1)
  }, [props.listLayers]);

  const handleChangeCurrentLayer = (
    selectedObject: LayerSettingsModelExtends | any,
    actionDetail: any
  ) => {
    setLayer(selectedObject);
    props.openLayerController?.changeCurrentLayer(selectedObject);
    props.openLayerController?.changeCurrentLayerOpacity(opacities, layer);
  };

  const handleChangeOpacityOfCurrentLayer = (event: any) => {
    let data = {
      ...layer,
      opacity: event.target.value,
    };
    let dataList = listLayers.map((item) => {
      if (item.value === data.value) {
        item.opacity = data.opacity;
      }
      return item;
    });
    let opacity = Number(data.opacity) / 100;
    props.openLayerController?.changeCurrentLayerOpacity(opacity, data);
    props.GetMapOpacity(props.mapData, layer.id, event.target.value)
    setLayer(data);
    setListLayers(dataList);
    if (opacities) {
      props.GetMapOpacity(props.mapData, layer.id, (opacities * 100).toString())
    }
  };

  if (listLayers.length === 0) return null;
  return (
    <div className="position-relative select-base-map-container">
      <div className="position-absolute select-base-map bg-white pt-4 pl-2 pr-2 pb-2 rounded">
        <div className="border-top pt-2">
          <label className="text-secondary">Chọn lớp cơ sở:</label>
          <Select
            defaultValue={layer}
            onChange={(selected, action) =>
              handleChangeCurrentLayer(selected, action)
            }
            options={listLayers}
            placeholder="Lớp hiện hành"
          />
        </div>
        <input
          id="ranger-input-current-layer"
          onChange={handleChangeOpacityOfCurrentLayer}
          className="w-100 opacity-range-control"
          type="range"
          value={layer.opacity}
          min="0"
          max="100"
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return ({
    mapData: state.mapData.mapData,
    openLayerController: state.openlayer.openLayerController,
  });
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetMapOpacity: MapDataStore.GetMapOpacity,
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(SelectCurrentLayerView);
