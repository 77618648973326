import React, { useEffect, useState } from "react";
import "./look-up-planning.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SearchByAddress from "./child-modules/search-address/search-address.view";
import PlanningByTypeView from "./child-modules/planning-type/planning-type.view";
import UrlCollect from "../../common/url-collect";
import SearchPlanningView from "./child-modules/search-planning-name/search-planning-name.view";
import * as statementActions from "../../redux/store/statement/statement.store";
import history from "../../common/history";
import * as mapHelper from "../../common/map";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import uongBiCoordinate from "../../common/uongbi_coordinate";
import {
  NotificationMessageType,
  NotificationPosition,
} from "../../utils/configuration";
function LookUpPlanningView(props) {
  const [planningType, setPlanningType] = useState([]);
  const [dataSelectBoxSearch, setDataSelectBoxSearch] = useState([]);
  const [selected, setSelected] = useState();
  const [show, setShow] = useState(true);
  let commentInput = null;

  useEffect(() => {
    getAllPlanningType();
  }, []);

  const getAllPlanningType = () => {
    statementActions
      .PlanningType()
      .then((res) => {
        if (res && res.content) {
          setPlanningType(res.content);
        }
      })
      .catch((err) => {
      });
  };

  let PATHNAME =
    "?key=" + new URLSearchParams(props.location.search).get("key");

  function createClassListLeftSelectMenu(pathName) {
    return PATHNAME === pathName ? "p-2 active-custom" : "p-2";
  }

  function handleSearchCoordinate(event) {
    event.preventDefault();
    let value = commentInput.value.trim();
    let coordinateList = value.split(",");
    if (coordinateList && coordinateList.length === 2) {
      let x = parseFloat(coordinateList[0]);
      let y = parseFloat(coordinateList[1]);
      if (checkAddressInPolyon(y, x)) {
        props.history.push(
          `${UrlCollect.SyntheticPlanning}?lng=${x}&lat=${y}&searchType=coordinate`
        );
      }
    }
  }

  function checkAddressInPolyon(x, y) {

    let isInside = false;
    props.listPolygon &&
      props.listPolygon.map((itemPolygon) => {
        if (
          mapHelper.isPointInPolygon(y,x,itemPolygon)
        ) {
          isInside = true;
          return;
        }
      });
    if (isInside) {
      history.push(
        `${UrlCollect.SyntheticPlanning}?lng=${x}&lat=${y}&searchType=place`
      )
      return true;
    } else {
      ShowNotification(
        viVN.Errors.CoordinateOutSidePolygon,
        NotificationMessageType.Warning,
        NotificationPosition.Center
      );
    }
    return false;




    // if (mapHelper.isPointInPolygon(y, x, uongBiCoordinate)) {
    //   history.push(
    //     `${UrlCollect.SyntheticPlanning}?lng=${x}&lat=${y}&searchType=place`
    //   )
    //   return true;
    // } else {
    //   ShowNotification(
    //     viVN.Errors.CoordinateOutSidePolygon,
    //     NotificationMessageType.Warning,
    //     NotificationPosition.Center
    //   );
    // }
    // return false;
  }

  return (
    <div className="container look-up-planning-container">
      <div className="row">
        <div className="col-3 left-menu">
          <SearchPlanningView
            dataSelectBoxSearch={dataSelectBoxSearch}
            setSelected={setSelected}
          />
          {show ? (
            <div className="row search-container no-gutters align-items-center">
              <div className="col-10">
                <SearchByAddress
                  onSelectAddress={(x, y) => checkAddressInPolyon(x, y)}
                />
              </div>
              <div className="col-2 text-right">
                <button
                  className="bg-transparent border-0"
                  onClick={() => setShow(false)}
                >
                  <img
                    src={require("../../assets/icon/planning-name.svg")}
                    alt="Planning Name"
                  />
                </button>
              </div>
            </div>
          ) : (
              <div className="row search-container no-gutters align-items-center">
                <div className="col-10">
                  <form onSubmit={handleSearchCoordinate}>
                    <input
                      type="text"
                      ref={(input) => {
                        commentInput = input;
                      }}
                      className="form-control"
                      placeholder="Nhập toạ độ VN2000 (Toạ độ X, Toạ độ Y)"
                    />
                  </form>
                </div>
                <div className="col-2 text-right">
                  <button
                    className="bg-transparent border-0"
                    onClick={() => setShow(true)}
                  >
                    <img
                      src={require("../../assets/icon/place.svg")}
                      alt="Planning Name"
                    />
                  </button>
                </div>
              </div>
            )}
          <div className="list-select-side-menu">
            <Link
              key={"SyntheticPlanning"}
              to={UrlCollect.SyntheticPlanning}
              className={createClassListLeftSelectMenu(
                UrlCollect.SyntheticPlanning
              )}
            >
              Tổng hợp quy hoạch
            </Link>
            {planningType &&
              planningType.length > 0 &&
              planningType.map((item, index) => (
                <Link
                  key={index}
                  to={UrlCollect.PlanningMap + "?key=" + item.id}
                  className={createClassListLeftSelectMenu("?key=" + item.id)}
                >
                  {item.name}
                </Link>
              ))}
          </div>
        </div>
        <div className="col-9">
          <PlanningByTypeView
            params={props}
            setDataSelectBoxSearch={setDataSelectBoxSearch}
            selected={selected}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  listPlanningCategoriesAll: state.mapData.listPlanningCategoriesAll,
  openLayerController: state.openlayer.openLayerController,
  planningType: state.statement.planningType,
  listPolygon: state.mapData.listPolygon,
});
export default connect(mapStateToProps)(LookUpPlanningView)
