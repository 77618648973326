/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import UrlCollect from "../../../common/url-collect";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import * as appActions from "../../../core/app.store";
import * as consultantCommunityActions from "../../../redux/store/consultant-community/consultant-community.store";
import { APIUrlDefault } from "../../../utils/configuration";

import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../languages/vi-VN.json";

import "./please-comment.scss";
import history from "../../../common/history";
import * as configuration from "../../../utils/configuration";
import { saveAs } from "file-saver";

const ANSWER_TYPE = {
  RADIO: "RADIO",
  TEXT_BOX: "TEXT_BOX",
  CHECK_BOX: "CHECK_BOX",
  DROPDOWN: "DROPDOWN",
};

function PleaseCommentView(props) {
  const { showLoading } = props;
  const { id } = props.match.params;

  const [data, setData] = useState("");
  const [doingModel, setDoingModel] = useState([]);
  const [resultModel, setResultModel] = useState([]);
  const [formTemplate, setFormTemplate] = useState([]);

  const currentPageIndexDoing = 1;
  const currentPageSizeDoing = 10;
  const [totalItemCountDoing, setTotalItemCountDoing] = useState(0);

  const currentPageIndexResult = 1;
  const currentPageSizeResult = 10;
  const [totalItemCountResult, setTotalItemCountResult] = useState(0);

  const { register, handleSubmit, errors } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    id ? onGetData() : history.push(UrlCollect.PleaseConsultTheCommunity);
  }, [id]);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetDetail(),
      onGetListConsultantCommunity({
        pageIndex: currentPageIndexDoing,
        pageSize: currentPageSizeDoing,
      }),
      onGetDetailFormTemplate(),
      onGetListExpiredForm({
        pageIndex: currentPageIndexResult,
        pageSize: currentPageSizeResult,
      }),
    ])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const onGetListConsultantCommunity = (data) => {
    return new Promise((resolve, reject) => {
      consultantCommunityActions.GetListConsultantCommunity(data).then(
        (res) => {
          setDoingModel(
            res &&
              res.content &&
              res.content.items &&
              res.content.items.length > 0
              ? res.content.items.filter((item) => item.id !== id)
              : []
          );
          setTotalItemCountDoing(
            res && res.content && res.content.totalItemCount
              ? res.content.totalItemCount
              : 0
          );
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const onGetListExpiredForm = (data) => {
    return new Promise((resolve, reject) => {
      consultantCommunityActions.GetListResultConsultantCommunity(data).then(
        (res) => {
          setResultModel(
            res &&
              res.content &&
              res.content.items &&
              res.content.items.length > 0
              ? res.content.items.filter((item) => item.id !== id)
              : []
          );
          setTotalItemCountResult(
            res && res.content && res.content.totalItemCount
              ? res.content.totalItemCount
              : 0
          );
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const onGetDetail = () => {
    return new Promise((resolve, reject) => {
      consultantCommunityActions.GetDetailConsultantCommunity(id).then(
        (res) => {
          setData(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const onGetDetailFormTemplate = () => {
    return new Promise((resolve, reject) => {
      consultantCommunityActions.GetDetailFormTemplate(id).then(
        (res) => {
          setFormTemplate(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const onSubmitComment = (data) => {
    if (!data) return;
    showLoading(true);

    let params = {
      formTemplateId: (formTemplate && formTemplate.id) || null,
      name: data.fullName,
      phoneNo: data.mobilePhone,
      email: data.email,
      address: data.address,
      suggestion: "",
      answers: [],
    };

    formTemplate &&
      formTemplate.questions &&
      formTemplate.questions.length > 0 &&
      formTemplate.questions.map((item) => {
        let answerObj = {};
        let answers = params.answers || [];
        let addition = data[`haveAdditional_${item.id}`];

        if (item.answerType === ANSWER_TYPE.RADIO) {
          let answerRadio = data[`answerRadio_${item.id}`];

          if (answerRadio) {
            answerObj = {
              questionId: item.id,
              answerId: parseInt(answerRadio),
            };

            if (addition) {
              answerObj = {
                ...answerObj,
                addition: addition,
              };
            }

            answers.push(answerObj);
          }
        } else if (item.answerType === ANSWER_TYPE.DROPDOWN) {
          let answerDropdown = data[`answerDropdown_${item.id}`];

          if (answerDropdown) {
            answerObj = {
              questionId: item.id,
              answerId: parseInt(answerDropdown),
            };

            if (addition) {
              answerObj = {
                ...answerObj,
                addition: addition,
              };
            }

            answers.push(answerObj);
          }
        } else if (item.answerType === ANSWER_TYPE.CHECK_BOX) {
          item.answers.map((answer) => {
            let answerCheckBox = data[`answerCheckBox_${item.id}_${answer.id}`];

            if (answerCheckBox) {
              answerObj = {
                questionId: item.id,
                answerId: answer.id,
              };

              if (addition) {
                answerObj = {
                  ...answerObj,
                  addition: addition,
                };
              }

              answers.push(answerObj);
            }
          });
        } else if (item.answerType === ANSWER_TYPE.TEXT_BOX) {
          let answerTextBox = data[`answerTextBox_${item.id}`];

          if (answerTextBox) {
            answerObj = {
              questionId: item.id,
              addition: answerTextBox,
            };

            answers.push(answerObj);
          }
        }

        params = {
          ...params,
          answers: answers,
        };
      });

    consultantCommunityActions.CreateFeedback(params).then(
      (res) => {
        ShowNotification(
          viVN.Success.FeedbackSuccess,
          NotificationMessageType.Success
        );
        showLoading(false);
        history.push("/y-kien-cong-dong");
      },
      (err) => {
        showLoading(false);
        ShowNotification(
          viVN.Errors[(err && err.errorType) || "UnableHandleException"],
          NotificationMessageType.Error
        );
      }
    );
  };

  const renderAnswerInput = (_question) => {
    if (!_question) return null;

    switch (_question.answerType) {
      case ANSWER_TYPE.RADIO:
        if (!_question.answers || _question.answers.length === 0) return null;
        return (
          _question.answers &&
          _question.answers.length > 0 &&
          _question.answers.map((answerItem, answerIndex) => (
            <div
              key={`answerRadio_${_question.id}_${answerItem.id}`}
              className="form-check"
            >
              <input
                className="form-check-input"
                type="radio"
                name={`answerRadio_${_question.id}`}
                value={answerItem.id}
                defaultChecked={answerIndex === 0 ? true : false}
                ref={register}
              />
              <label
                className="form-check-label"
                htmlFor={`answerRadio_${_question.id}`}
              >
                {answerItem.title}
              </label>
            </div>
          ))
        );
      case ANSWER_TYPE.TEXT_BOX:
        return (
          <div
            key={`answerTextBox_${_question.id}`}
            className="form-group w-100"
          >
            <input
              type="text"
              name={`answerTextBox_${_question.id}`}
              className="form-control"
              ref={register}
            />
          </div>
        );
      case ANSWER_TYPE.CHECK_BOX:
        if (!_question.answers || _question.answers.length === 0) return null;
        return (
          _question.answers &&
          _question.answers.length > 0 &&
          _question.answers.map((answerItem, answerIndex) => (
            <div
              key={`answerCheckBox_${_question.id}_${answerItem.id}`}
              className="form-check"
            >
              <input
                className="form-check-input"
                type="checkbox"
                name={`answerCheckBox_${_question.id}_${answerItem.id}`}
                ref={register}
              />
              <label
                className="form-check-label"
                htmlFor={`answerCheckBox_${_question.id}_${answerItem.id}`}
              >
                {answerItem.title}
              </label>
            </div>
          ))
        );
      case ANSWER_TYPE.DROPDOWN:
        if (!_question.answers || _question.answers.length === 0) return null;
        return (
          <div
            key={`answerDropdown_${_question.id}`}
            className="form-group w-100"
          >
            <select
              class="form-control"
              name={`answerDropdown_${_question.id}`}
              ref={register}
            >
              <option value="" selected disabled>
                Lựa chọn của bạn
              </option>
              {_question.answers.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container">
      {data && (
        <div className="please-comment mt-4 mb-4">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 border">
              <div className="detail-please-comment">
                <h5 className="title mt-4 mb-3">{data.planningName}</h5>
                <div
                  className="content mt-4"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                ></div>
              </div>

              {!data.isExpired && <div className="hr"></div>}

              {!data.isExpired && (
                <div className="submit-comments mb-4" id="getcommunityopinion">
                  <h5 className="title mb-3">
                    ĐÓNG GÓP Ý KIẾN TRỰC TIẾP VỀ ĐỒ ÁN QUY HOẠCH HOẶC{" "}
                    <a
                      className="btn btn-info ml-2 cursor-pointer"
                      onClick={() => {
                        if (data && data.documentPath) {
                          saveAs(
                            configuration.APIUrlDefault + data.documentPath,
                            data.name
                          );
                        } else {
                          ShowNotification(
                            viVN.Warning.NotFileDownload,
                            NotificationMessageType.Warning
                          );
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        color="white"
                        className="mr-2"
                      />
                      <span className="text-uppercase">Tải biểu mẫu</span>
                    </a>
                  </h5>
                  <b>A. THÔNG TIN CƠ QUAN, CÁ NHÂN ĐƯỢC XIN Ý KIẾN</b>
                  <form
                    className="mt-3 form-please-comment"
                    onSubmit={handleSubmit(onSubmitComment)}
                    autoComplete="off"
                  >
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label htmlFor="name" className="font-weight-bold">
                          Họ và tên (*)
                        </label>
                        <input
                          type="text"
                          name="fullName"
                          className={
                            "form-control " +
                            (errors.fullName && errors.fullName.type
                              ? "border-danger"
                              : "")
                          }
                          ref={register({ required: true })}
                        />
                        {errors.fullName &&
                          errors.fullName.type === "required" && (
                            <span className="error">
                              Trường này là bắt buộc
                            </span>
                          )}
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label className="font-weight-bold">Địa chỉ (*)</label>
                        <input
                          type="text"
                          name="address"
                          className={
                            "form-control " +
                            (errors.address && errors.address.type
                              ? "border-danger"
                              : "")
                          }
                          ref={register({ required: true })}
                        />
                        {errors.address &&
                          errors.address.type === "required" && (
                            <span className="error">
                              Trường này là bắt buộc
                            </span>
                          )}
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label className="font-weight-bold">
                          Số điện thoại (*)
                        </label>
                        <input
                          type="text"
                          name="mobilePhone"
                          className={
                            "form-control " +
                            (errors[`mobilePhone`] ? "border-danger" : "")
                          }
                          ref={register({
                            required: true,
                            pattern: /(03|07|08|09|05])+([0-9]{8})\b/i,
                          })}
                        />
                        {errors.mobilePhone &&
                          errors.mobilePhone.type === "required" && (
                            <span className="error">
                              Trường này là bắt buộc
                            </span>
                          )}
                        {errors.mobilePhone &&
                          errors.mobilePhone.type === "pattern" && (
                            <span className="error">
                              Số điện thoại không hợp lệ
                            </span>
                          )}
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group">
                        <label className="font-weight-bold">Email (*)</label>
                        <input
                          type="text"
                          name="email"
                          className={
                            "form-control " +
                            (errors[`email`] ? "border-danger" : "")
                          }
                          ref={register({
                            required: true,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          })}
                        />
                        {errors.email && errors.email.type === "required" && (
                          <span className="error">Trường này là bắt buộc</span>
                        )}
                        {errors.email && errors.email.type === "pattern" && (
                          <span className="error">
                            Email không đúng định dạng
                          </span>
                        )}
                      </div>

                      {formTemplate &&
                        formTemplate.questions &&
                        formTemplate.questions.map(
                          (questionItem, questionIndex) => (
                            <div
                              key={`question_${questionItem.id}`}
                              className="col-12"
                            >
                              <label className="font-weight-bold">
                                {`${questionIndex + 1}. ${questionItem.title}`}
                              </label>

                              <div className="form-group d-flex justify-content-between">
                                {renderAnswerInput(questionItem)}
                              </div>

                              {questionItem.haveAdditional === true &&
                                questionItem.answerType !==
                                  ANSWER_TYPE.TEXT_BOX && (
                                  <div className="form-group">
                                    <label className="font-weight-bold">
                                      Ý kiến khác
                                    </label>
                                    <textarea
                                      name={`haveAdditional_${questionItem.id}`}
                                      rows={2}
                                      className="form-control"
                                      ref={register}
                                    ></textarea>
                                  </div>
                                )}
                            </div>
                          )
                        )}
                    </div>

                    <button
                      type="submit"
                      className="btn btn-info text-uppercase pl-5 pr-5"
                    >
                      Gửi ý kiến
                    </button>
                  </form>
                </div>
              )}
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
              <div>
                <div className="header-result">
                  <div className="title">
                    <Link
                      to={UrlCollect.PleaseConsultTheCommunity}
                      className="text-uppercase"
                    >
                      QUY HOẠCH ĐANG XIN Ý KIẾN
                    </Link>
                  </div>
                </div>
                <div className="content-result">
                  {doingModel && doingModel.length > 0 ? (
                    doingModel.map((item) => (
                      <div key={item.id} className="item">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <img
                              src={APIUrlDefault + item.avatar}
                              alt={item.planningName}
                              className="img-fluid w-100 mb-2 mb-md-0"
                            />
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 pl-3 pl-md-3">
                            <Link to={UrlCollect.PleaseComment + "/" + item.id}>
                              {item.planningName}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center mb-4">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        color="white"
                        className="mr-2"
                      />
                      Không có dữ liệu
                    </div>
                  )}
                </div>
              </div>

              <div>
                <div className="header-result">
                  <div className="title">
                    <Link
                      to={UrlCollect.SummaryIdeas}
                      className="text-uppercase"
                    >
                      KẾT QUẢ XIN Ý KIẾN
                    </Link>
                  </div>
                </div>
                <div className="content-result">
                  {resultModel && resultModel.length > 0 ? (
                    resultModel.map((item) => (
                      <div key={item.id} className="item">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <Link
                              to={
                                UrlCollect.SummaryIdeas + "?search=" + item.id
                              }
                            >
                              <img
                                src={APIUrlDefault + item.avatar}
                                alt={item.title}
                                className="img-fluid w-100 mb-2 mb-md-0"
                              />
                            </Link>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 pl-3 pl-md-3">
                            <Link
                              to={
                                UrlCollect.SummaryIdeas + "?search=" + item.id
                              }
                            >
                              {item.planningName}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">Không có dữ liệu</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PleaseCommentView);
