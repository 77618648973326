const FILTER_TOOL_HANDLEFUNCTION = 'FILTER_TOOL/FILTER_TOOL_HANDLEFUNCTION';

const CreateHandleFunctionAction = data => ({ type: FILTER_TOOL_HANDLEFUNCTION, data })

export const FilterToolCreateHandleFuction = (handleFunction) => {
    return dispatch => {
        dispatch(CreateHandleFunctionAction(handleFunction))
    }
}

const InitState = {}

export default function FilterToolReducer(state = InitState, action) {
    switch (action.type) {
        case FILTER_TOOL_HANDLEFUNCTION:
            return {
                ...state, ...action.data
            }
        default: return state
    }
}