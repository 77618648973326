/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import MultiPolygon from "ol/geom/MultiPolygon";
import { getCenter } from "ol/extent";

const RenderInfoTable = ({
  data,
  handleClickShowAllMapResult,
  isShowMapResult,
  headerTable,
  ...props
}) => {
  const [isActive, setIsActive] = useState(null);
  const onDownloadExcelOffline = () => {
    var tab_text = "<table border='2px'><thead><tr>";
    var _header = "";
    headerTable &&
      headerTable.forEach((_item, _index) => {
        const _th =
          "<th style='color:white; background-color:#007bff; height:70;'>" +
          (_item.alias || "") +
          "</th>";
        _header += _th;
      });
    tab_text += _header + "</tr></thead><tbody>";

    var _body = "";
    data &&
      data.forEach((_item, _i) => {
        _body += "<tr>";
        var _row = "";
        headerTable.forEach((_title, _index) => {
          const _td = "<td>" + (_item[_title.col] || "") + "</td>";
          _row += _td;
        });
        _body += _row + "</tr>";
      });

    tab_text += _body + "</tbody></table>";
    tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
    tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
    tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

    const sa = window.open(
      "data:application/vnd.ms-excel," + encodeURIComponent(tab_text)
    );
    return sa;
  };
  const onViewMap = (data, index) => {
    const multipolygon = new MultiPolygon(data.geom.coordinates);
    const polygon = data.geo;
    const center = getCenter(multipolygon.getExtent());
    props.openLayerController.handleOnclickRecordTable(center, polygon);
    if (index === isActive) {
      setIsActive(null);
    } else {
      setIsActive(index);
    }
  };
  return (
    <div className="render-map-child-container pt-2 pl-2 pr-2">
      <div className="d-flex align-items-center justify-content-between pt-2 pb-2 pl-2">
        <div className="map-result-number d-flex align-items-center">
          <label className="mb-0">
            Có <b>{data.length}</b> kết quả
          </label>
          <span
            onClick={onDownloadExcelOffline}
            className="text-success ml-3 export-excel"
            data-tip="Xuất file excel"
          >
            <FontAwesomeIcon icon={faFileExcel} />
          </span>
          <ReactTooltip place="right" effect="solid" />
        </div>
        <div className="map-result-view">
          {!isShowMapResult ? (
            <img
              src={require("../../../../assets/icon/last.svg")}
              alt="Last"
              className="img-last"
              onClick={handleClickShowAllMapResult}
            ></img>
          ) : (
            <img
              src={require("../../../../assets/icon/last.svg")}
              alt="Last"
              className="img-last-reverse"
              onClick={handleClickShowAllMapResult}
            ></img>
          )}
          <img
            src={require("../../../../assets/icon/close-black.svg")}
            alt="Close"
            className="img-close ml-3"
            onClick={() =>
              props.filterTool.clearData && props.filterTool.clearData()
            }
          ></img>
        </div>
      </div>

      <table className="table table-map-result">
        <thead>
          <tr>
            {headerTable &&
              headerTable.length > 0 &&
              headerTable.map((item, index) => (
                <th
                  scope="col"
                  key={index}
                  className="text-truncate bg-primary text-white"
                >
                  {item.alias}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.length > 0 &&
            data.map((item, index) => (
              <tr
                style={
                  isActive === index
                    ? { backgroundColor: "#007bff", color: "white" }
                    : { background: "" }
                }
                onClick={() => onViewMap(item, index)}
                key={index}
              >
                {headerTable &&
                  headerTable.length > 0 &&
                  headerTable.map((item1, index) => (
                    <th scope="row" key={index}>
                      {item[item1.col]}
                    </th>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  filterTool: state.filterTool,
  openLayerController: state.openlayer.openLayerController,
});

export default connect(mapStateToProps, null)(RenderInfoTable);
