/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faBook,
} from "@fortawesome/free-solid-svg-icons";

import ViLanguages from "../../../languages/vi";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import LeftMenuModels from "../../../models/planning-map-view/left-menu.model";
import { connect } from "react-redux";
import LayerCategoryModels from "../../../models/map-data-model-b/layer-category-models";
import LayerSettingsModels from "../../../models/map-data-model-b/layer-setting-models";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../../redux/store/map-data/map-data.store";
import PlanningRelationModel, {
  PlanningMapModels,
  BoundariesViewModel,
} from "../../../models/map-data-model-b/new-planning-relation.model";

import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  getUserInfo,
} from "../../../utils/configuration";

import RenderInfoTable from "./childs/render-info-table.view";

import "./left-menu.scss";

const LanguageCollects = ViLanguages;

function LeftMenuView(props: LeftMenuModels.LeftMenuProps) {


  const isLogin = getUserInfo() ? true : false;
  const [isShowMapResult, setShowMapResult] = useState(false);
  const [headerTable, setHeaderTable] = useState<any>();

  useEffect(() => {
    setHeaderTable(props.headerTable);
  }, [props.headerTable]);

  function handleClickIconLeftNavbar(id: string) {
    handleClickToggleLeftNavBar();
    const buttonElement = document.querySelector(
      `button[aria-controls="${id}"]`
    ) as HTMLElement;
    if (buttonElement.classList.contains("collapsed")) buttonElement.click();
  }

  function handleClickToggleLeftNavBar() {
    props.toggleStateIsLeftNavbarHide();
    if (!props.isLeftNavbarHide) {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.add("hide-left-navbar");
    } else {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.remove("hide-left-navbar");
    }
  }

  function handleToggleDisplayLayer(
    layerTarget: LayerSettingsModels.LayerSettingsModel
  ) {
    props.openLayerController?.toggleDisplayLayer(layerTarget);
  }

  function handleToggleDisplayBoundaries(
    boundariesObject: BoundariesViewModel,
    isDisplay: boolean
  ) {
    props.openLayerController.toggleDisplayBoundariesRelation(
      boundariesObject,
      isDisplay
    );
  }

  function handleToggleShowAllMapResult() {
    if (!isShowMapResult) {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.add("show-map-result");
      setShowMapResult(true);
    } else {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.remove("show-map-result");
      setShowMapResult(false);
    }
  }

  useEffect(() => {
    props.GetListPlanningRelationByPlanningId(props.planningId);
  }, []);

  const infoTable = {
    handleClickShowAllMapResult: handleToggleShowAllMapResult,
    data: props.informationForTableResult,
    isShowMapResult: isShowMapResult,
    headerTable: props.headerTable ? props.headerTable : [],
  };


  return (
    <div
      className={
        "h-100 position-relative custom-container-left-menu" +
        (isShowMapResult ? " show-map-result" : "")
      }
    >
      <div className="navbar-custom h-100 position-relative" id="accordion">
        <div className="header-navbar-custom position-relative">
          <button
            className="btn "
            data-toggle="collapse"
            data-target="#mapLayersTab"
            aria-expanded="true"
            aria-controls="mapLayersTab"
          >
            {LanguageCollects.planningMapView_map}
          </button>
          <button
            id="ButtonControlMapInfomationTab"
            className="btn collapsed"
            data-toggle="collapse"
            data-target="#mapInfomationTab"
            aria-expanded="false"
            aria-controls="mapInfomationTab"
          >
            {LanguageCollects.planningMapView_infomation}
          </button>
          <button
            className="btn collapsed"
            data-toggle="collapse"
            data-target="#relativePlanningTab"
            aria-expanded="false"
            aria-controls="relativePlanningTab"
            hidden={props.isLeftNavbarHide}
          >
            {LanguageCollects.planningMapView_relatedPlanning}
          </button>
          <button
            id="btnRelativeResultTab"
            className="btn collapsed"
            data-toggle="collapse"
            data-target="#relativeResultTab"
            aria-expanded="false"
            aria-controls="relativeResultTab"
            hidden={props.isLeftNavbarHide}
          >
            {LanguageCollects.planningMapView_result}
          </button>
          <button
            className="btn btn-hide-left-navbar position-absolute"
            onClick={() => handleClickToggleLeftNavBar()}
          >
            <FontAwesomeIcon
              icon={props.isLeftNavbarHide ? faAngleRight : faAngleLeft}
              color="white"
              size="lg"
            />
          </button>
        </div>
        <div hidden={props.isLeftNavbarHide}>
          <div
            id="mapLayersTab"
            className="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            {props.listGroupLayer.map((data, index: number) => (
              <RenderMapChild
                toggleLayerAction={(
                  data: LayerSettingsModels.LayerSettingsModel
                ) => handleToggleDisplayLayer(data)}
                key={index}
                data={data}
                filterTool={props.filterTool}
                openLayerController={props.openLayerController}
              />
            ))}
          </div>
          <div
            id="mapInfomationTab"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <details id="mapInfomationContainer">
              <summary>
                Thông tin quy hoạch
                <img
                  className="position-absolute icon-dropdow right-position"
                  src={require("../../../assets/icon/arrow-down.png")}
                  alt="arrow-down"
                />
                <img
                  className="position-absolute icon-dropright right-position"
                  src={require("../../../assets/icon/arrow-right.png")}
                  alt="arrow-right"
                />
              </summary>
              <div className="planning-infomation">
                {props.listMapInfomations?.map((data, index) =>
                  data.value ? (
                    <div key={index}>{`${data.label} : ${data.value}`}</div>
                  ) : null
                )}
                {props.listMapInfomations?.length === 0 && (
                  <p className="pt-3 text-center">Không có dữ liệu</p>
                )}
              </div>
            </details>
            <details>
              <summary>
                Quản lý hồ sơ thiết kế
                <img
                  className="position-absolute icon-dropdow right-position"
                  src={require("../../../assets/icon/arrow-down.png")}
                  alt="arrow-down"
                />
                <img
                  className="position-absolute icon-dropright right-position"
                  src={require("../../../assets/icon/arrow-right.png")}
                  alt="arrow-right"
                />
              </summary>
              {isLogin ? (
                <div>
                  <Link
                    className="records-link d-block position-relative"
                    to={"/quan-ly-ho-so?planningId=" + props.planningId}
                  >
                    Hồ sơ thiết kế <span className="position-absolute">→</span>
                  </Link>
                  <Link
                    className="records-link d-block position-relative"
                    to={"/quan-ly-ho-so?planningId=" + props.planningId}
                  >
                    Văn bản pháp lý<span className="position-absolute">→</span>
                  </Link>
                </div>
              ) : (
                  <div className="records-link d-block position-relative">
                    Bạn phải đăng nhập để xem thông tin
                  </div>
                )}
            </details>
            <details>
              <summary>
                Chú giải
                <img
                  className="position-absolute icon-dropdow right-position"
                  src={require("../../../assets/icon/arrow-down.png")}
                  alt="Arrow Down"
                />
                <img
                  className="position-absolute icon-dropright right-position"
                  src={require("../../../assets/icon/arrow-right.png")}
                  alt="Arrow Right"
                />
              </summary>
            </details>
          </div>
          <div
            id="relativePlanningTab"
            className="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordion"
          >
            {props.listPlanningRelationShips?.map((data, index) => (
              <RenderRelatedPlanning
                handleClick={handleToggleDisplayBoundaries}
                key={index}
                data={data}
              />
            ))}
            {props.listPlanningRelationShips?.length === 0 && (
              <p className="p-3 text-center">
                Không có dự án liên quan nào được tìm thấy
              </p>
            )}
          </div>
          <div
            id="relativeResultTab"
            className="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordion"
          >
            {props.informationForTableResult &&
              props.informationForTableResult.length > 0 ? (
                <RenderInfoTable {...infoTable} />
              ) : (
                <p className="p-3 text-center">Không có kết quả</p>
              )}
          </div>
        </div>
        <div
          className="header-navbar-custom-icons-style text-center position-absolute"
          hidden={!props.isLeftNavbarHide}
        >
          <button
            title={LanguageCollects.planningMapView_map}
            onClick={() => handleClickIconLeftNavbar("mapLayersTab")}
          >
            <FontAwesomeIcon icon={faMap} color="#11998E" size="lg" />
          </button>
          <button
            title={LanguageCollects.planningMapView_infomation}
            onClick={() => handleClickIconLeftNavbar("mapInfomationTab")}
          >
            <div className="information-circle-warp">
              <img
                src={require("../../../assets/icon/information.svg")}
                alt="Information"
              />
            </div>
          </button>
          <button
            title={LanguageCollects.planningMapView_relatedPlanning}
            onClick={() => handleClickIconLeftNavbar("relativePlanningTab")}
          >
            <img
              src={require("../../../assets/icon/document-duplicate.svg")}
              alt="Document Duplicate"
            />
          </button>
        </div>
      </div>
      <div
        className={`guide-user ${props.isLeftNavbarHide ? "guide-user-hide" : ""
          } position-absolute pb-2 pt-2 pl-3`}
      >
        <FontAwesomeIcon
          icon={faBook}
          color="#747474"
          data-toggle="modal"
          data-target=".bd-example-modal-lg"
        />
        <span
          data-toggle="modal"
          data-target=".bd-example-modal-lg"
          hidden={props.isLeftNavbarHide}
          className="ml-2"
        >
          {LanguageCollects.planningMapView_guideToUse}
        </span>
      </div>
    </div>
  );
}

function RenderRelatedPlanning(props: any) {
  const data: PlanningRelationModel = props.data;

  return (
    <div className="related-planning-container pt-2 pl-2 pr-2 pb-0">
      <h6 className="mb-0">
        <img
          src={require("../../../assets/icon/circle.png")}
          alt="Circle"
          className="pr-1"
        />
        {data.planningTypeName}
      </h6>
      <div className="pl-3 pr-2 pt-2 pb-0">
        {data.planningMapModels.map((planningData, index) => (
          <RenderItemFieldRelatedPlanning
            handleClick={props.handleClick}
            key={index}
            data={planningData}
          />
        ))}
      </div>
    </div>
  );
}

function RenderItemFieldRelatedPlanning(props: any) {
  const data: PlanningMapModels = props.data;
  const isChecked = useRef(false);
  const handleClick = () => {
    if (data.boundariesView) {
      props.handleClick(data.boundariesView, !isChecked.current);
    } else {
      !isChecked.current &&
        ShowNotification(
          "Bạn phải cài đặt ranh giới liên quan",
          NotificationMessageType.Warning
        );
    }
    isChecked.current = !isChecked.current;
  };
  if (!(data && data.planningName)) return null;
  return (
    <label className="cursor-pointer">
      <input
        type="checkbox"
        id={`planning-relationship-checkbox${data.planningName}`}
        onClick={() => handleClick()}
      />{" "}
      {data.planningName}
    </label>
  );
}

function RenderMapChild(props: any) {
  const data: LayerCategoryModels.LayerCategoryModel = props.data;
  const [layerSetting, setLayerSetting] = useState<
    LayerSettingsModels.LayerSettingsModel[]
  >(props.data.layer_settings);
  const [labelFlexible, setLabelFlexible] = useState("Chọn tất");
  useEffect(() => { }, [props.openLayerController]);

  function toggleSelectAll() {
    if (isEveryCheckboxChecked()) {
      selectOrDeselectAllCheckBox(false);
    } else {
      selectOrDeselectAllCheckBox();
    }
  }

  function isEveryCheckboxChecked() {
    let result = true;
    const listCheckbox = Array.from(
      document.querySelectorAll(`input[data-select-id="${data.folder_name}"]`)
    );
    listCheckbox.map((checkbox) => {
      if (!(checkbox as HTMLInputElement).checked) {
        result = false;
        return true;
      }
    });
    return result;
  }
  function selectOrDeselectAllCheckBox(isSelect = true) {
    const listCheckbox = Array.from(
      document.querySelectorAll(`input[data-select-id="${data.folder_name}"]`)
    );
    listCheckbox.map((checkboxElement) => {
      const checkbox = checkboxElement as HTMLInputElement;
      if (checkbox.checked != isSelect) checkbox.click();
    });
  }

  function handleClickCheckbox(LayerIndex: number, newValue: boolean) {
    const checkboxAll = document.querySelectorAll(
      `input[data-select-all-id="${data.folder_name}"]`
    )[0] as HTMLInputElement;
    if (!newValue) {
      props.openLayerController.removeHiglightVectorLayer &&
        props.openLayerController.removeHiglightVectorLayer();
    }
    const oldLayerSetting = [...layerSetting];
    oldLayerSetting[LayerIndex].is_check = newValue;
    setLayerSetting(oldLayerSetting);
    props.toggleLayerAction(oldLayerSetting[LayerIndex]);
    if (checkboxAll) {
      if (isEveryCheckboxChecked()) {
        checkboxAll.checked = true;
        setLabelFlexible("Bỏ chọn tất cả");
      } else {
        checkboxAll.checked = false;
        setLabelFlexible("Chọn tất");
      }
    }
  }

  return (
    <div className="render-map-child-container pt-2 pl-2 pr-2">
      <details>
        <summary className="mb-1">
          <img src={require("../../../assets/icon/circle.png")} alt="Cirle" />{" "}
          {data.folder_name}
          <img
            className="position-absolute icon-dropdow right-position"
            src={require("../../../assets/icon/arrow-down.png")}
            alt="Arrow Down"
          />
          <img
            className="position-absolute icon-dropright right-position"
            src={require("../../../assets/icon/arrow-right.png")}
            alt="Arrow Right"
          />
        </summary>
        <div className="select-container">
          {data.layer_settings.length > 1 && (
            <label className="d-block">
              <input
                type="checkbox"
                className="mr-1"
                data-select-all-id={data.folder_name}
                onClick={() => toggleSelectAll()}
              />
              {labelFlexible}
            </label>
          )}
          {layerSetting.map((item, index: number) => (
            <label key={index} className="d-block">
              <input
                type="checkbox"
                data-select-id={data.folder_name}
                checked={item.is_check}
                onChange={() => { }}
                data-left-menu-layer-id={item.name}
                className="mr-1"
                onClick={() => handleClickCheckbox(index, !item.is_check)}
              />
              {item.name}
            </label>
          ))}
          <hr className="mb-0 mt-0" />
        </div>
      </details>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  listMapInfomations: state.mapData.infomations,
  informationForTableResult: state.mapData.dataForTable,
  listPlanningRelationShips: state.mapData.planningRelationShips,
  listPlanningBoundaries: state.mapData.listPlanningBoundaries,
  openLayerController: state.openlayer.openLayerController,
  headerTable: state.mapData.headerTable,
  filterTool: state.filterTool,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetListPlanningRelationByPlanningId:
        MapDataStore.GetListPlanningRelationByPlanningId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenuView);
